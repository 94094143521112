.row {
    padding: 5px;
    border-bottom: 1px solid rgba(191,204,214,.3);
}

.row > div {
    padding: 5px;
}

.colTime {
    display: flex;
    justify-content: flex-end;
}

.title[class*="ant-typography"] {
    color: #48aff0;
}
.partial .title[class*="ant-typography"] {
    padding-left: 20px;
}

.time[class*="ant-typography"] {
    font-weight: bold;
    color: #48aff0;
}

