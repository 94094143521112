.menuWrapper {
    margin-right: 2rem;
}

.menuBtn {
    width: 3rem;
    height: 3rem;

    border-color: transparent !important;
}

.menuBtn svg {
    font-size: 2rem;
}

.menuRow {
    margin-bottom: 20px;
    cursor: pointer;
}

.menuItem {
    border-color: transparent !important;
}

.menuItem svg {
    font-size: 3rem;
}

