.wrapper {
    width: 80%
}
.wrapper.mobile {
    width: 100%
}

.btn {
    border-color: #adadad;
    margin-right: 1rem;
}

.select {
    padding-bottom: 1rem;
}

.select > div {
    border-color: #adadad !important;
}
