.row {
    padding: 5px;
    border-bottom: 1px solid rgba(191,204,214,.3);
}

.row > div {
    padding: 5px;
}

.row.MEETING {
    background-color: rgba(191,204,214,.15);
}

.header {
    font-weight: bold;
    font-size: 1.5em;
}

.colTime {
    display: flex;
    justify-content: flex-end;
}

.title[class*="ant-typography"] {
    color: #48aff0;
}

.time[class*="ant-typography"] {
    font-weight: bold;
    color: #48aff0;
}

.description_meeting {
    color: #48aff0;
}