.row {
    background-color: #394b59;
    padding: 1rem;
}

.logo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.menu.mobile {
    text-align: right;
}

.filter {
    display: flex;
    align-items: center;
}

.filter.mobile {
    padding-top: 2rem;
    justify-content: center;
}

.col {
    justify-content: center;
    align-items: center;
}

.col.mobile {
    text-align: center;
}

.logo.mobile {
    justify-content: flex-start;
}


