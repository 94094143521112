@import '~antd/dist/antd.dark.css';

@font-face {
    font-family: "Peak";
    src: url("resources/Peak-Regular.woff") format("woff");
}

* {
    font-family: 'Peak', -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1rem;
}

#root {
    max-width: 1920px;
    margin: auto;
    background-color: #10161a;
}

h3.ant-typography {
    margin-bottom: 0;
}

a.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-picker,
.ant-picker-separator {
    color: rgba(255, 255, 255, 0.85);
}

.ant-picker:hover, .ant-picker-focused,
.ant-btn:hover, .ant-btn:focus {
    color: #ffffff;
    border-color: #ffffff;
}

.ant-picker-input > input:hover {
    border-color: #ffffff;
    border-right-width: 1px !important;
}

.ant-picker-clear {
    background: #394b59;
}

.ant-btn-primary {
    color: #10161a;
    background: #48aff0;
    border-color: #48aff0;
}

::-webkit-scrollbar-thumb {
    background-color: #454a4d;
}

::-webkit-scrollbar-corner {
    background-color: #181a1b;
}

::-webkit-scrollbar {
    background-color: #202324;
    color: #aba499;
}